<template>
  <default-field
    v-bind="labelProps"
    @listen="handleListen"
    ref="def"
  >
<!--    :isRegistered="false"-->
    <form-group
      ref="pGroup"
      v-bind="labelProps"
    >
      <div  class="dynamic-box">
        <draggable v-model="lists" filter=".undraggable" handle=".dargBtn" @start="startDraggable" @end="endDraggable">
          <template #item="{element,index}">
            <div class="field-box" :key="key">
              <form-group
                :column="index"
              >
<!--                <div class="field-box-name"> {{ index+1 }}</div>-->
                <json-render :render-data="element"></json-render>

                <div class="field-box-tool" v-show="!isDisabled">
                  <quick-icon class="field-box-del cursor-pointer" icon="el-icon-delete" size="16" @click="delField(index)"/>
                  <quick-icon v-if="sortable" class="field-box-romve dargBtn cursor-move" icon="el-icon-rank" size="16"/>
                </div>
              </form-group>
            </div>


          </template>
          <template #header>
            <div class="dir-left-wrap main-between">
              <div>
                <el-button  :disabled="(max > 0 && max <= lists.length) || isDisabled"  :size="size" @click="_addField">{{ __('Add') }}</el-button>
                <el-button v-if="load" :disabled="(max > 0 && max <= lists.length) || isDisabled"  :size="size" @click="openDialog">{{loadTitle}}</el-button>
              </div>
              <div style="color:#c9c9c9;" v-if="max">注：最多添加组数  {{ max }}/{{ lists.length }}</div>
            </div>
          </template>
        </draggable>
      </div>

    </form-group>
  </default-field>

</template>

<script>
  import FormGroup from '../FormGroup';
  import draggable from 'vuedraggable'
  import {computed, ref, useAttrs, onMounted, getCurrentInstance,nextTick} from "vue";
  import {baseProps} from '../Composition/FormField';

  export default {
    name: 'DynamicField',
    componentType: 'form',
    components: {
      draggable,
      FormGroup
    },
    props: {
      ...baseProps,
      fieldJson: {
        type: Object,
        default: () => {
        }
      },
      fieldData: {
        type: [Array],
        default: () => []
      },
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 0
      },
      loadTitle:{
        type: String,
        default: 'Select'
      },
      loadMode:{
        type: String,
        default: 'submit'
      },
      size:{
        type: String,
        default: 'default'
      },
      load:{
        type: String,
        default: ''
      },
      sortable:{
        type:Boolean,
        default:false,
      }
    },
    data() {
      return {
        tempData: {}
      }
    },
    setup(props) {

      const attrs = useAttrs();
      const value = ref('');
      const def = ref(null);
      const key = ref(1);
      let fields = []
      const pGroup = ref(null)
      const tempData = [];
      const lists = ref([]);
      const {proxy} = getCurrentInstance();
      const error = ref('')
      /**
       * label
       */
      const labelProps = computed(() => {
        return {
          ...props,
          ...attrs,
          disabled: isDisabled.value,
          errors:error.value
        };
      });


      /**
       * 获取当前值
       */
      const getFieldValue = () => {

        fields = proxy.$refs.pGroup.getFields();
        const data = {};
        return _.tap(data, formData => {
          _.each(fields, field => {
            field.fill(formData);
          });
        });
      };

      /**
       * 填充formArr
       * @param formData
       */
      const fill = (formData) => {
        let data = getFieldValue();
        return formData[props.column] = JSON.stringify(data);
      };


      /** 处理表单重置 **/
      const resetField = () => {
        value.value = initialValue();
      };

      /**
       * 初始化值
       */
      const initialValue = () => {
        return !(props.default === undefined || props.default === null)
          ? props.default
          : '';
      };


      /**
       * 验证form
       * @param callback
       */
      const validate = function (callback) {
        console.log('-------------')
        // return true;
        if(props.min > 0 && lists.value.length < props.min){
          return '不能少于' + props.min + "组数据"
        }
        if(props.max > 0 && lists.value.length > props.max){
          return '不能大于' + props.max + "组数据"
        }

        return true
      };

      const isDisabled = ref(false)
      const handleListen = (event) => {
        if (event && event.action) {
          switch (event.action) {
            case 'disabled':
              isDisabled.value = event.params;
              let fields = proxy.$refs.pGroup.getFields();
              fields.map((item, index) => {
                item.setDisabled(event.params)
              })
              break;
          }
        }
      }


      onMounted(() => {
        value.value = initialValue();
        isDisabled.value = props.disabled
        props.fieldData.map((item) => {
          item.index_key = Math.random()
          lists.value.push(item)
        })
        if (props.min > 0 && props.min > props.fieldData.length) {
          // eslint-disable-next-line for-direction
          for (var i = 0; i <= props.min - lists.value.length; i++) {
            _addField()
          }
        }
      })


      const _addField = function () {
        const obj = JSON.parse(JSON.stringify(props.fieldJson))
        obj.index_key = Math.random()
        lists.value = lists.value.concat(obj)
      }

      const delField = function (index) {
        startDraggable()
        lists.value.splice(index, 1)
        endDraggable()
      }


      const startDraggable = function () {
        const data = getFieldValue()
        for(var i in data){
          tempData[lists.value[i].index_key] = data[i]
        }
      }

      /**
       *
       */
      const endDraggable = function () {
        key.value++
        let fields = proxy.$refs.pGroup.getFields();
        console.log('------tempData--',tempData)
        nextTick(() => {
          fields.map((item, index) => {
            item.resetField(tempData[lists.value[index].index_key])
          })
        })
      }

      const openDialog = function (){
        let openContent = props.load
        Quick.api.open(openContent, {
          component: 'dialog',
          title:props.loadTitle,
          beforeClose: function (event, params, done) {
            done()
            if(event !== 'submit'){
              return
            }
            // console.log('------json-filed----->',event, params)
            if(Array.isArray(params)){
              let addData = [];
              let map = new Map();
              params.forEach((item) => {
                let val = JSON.parse(JSON.stringify(props.fieldJson));
                val.index_key = Math.random()
                item.index_key = val.index_key
                map.set(item.index_key,item)
                addData = addData.concat(val)
              })

              if(props.loadMode === 'submit'){
                lists.value = []
              }
              addData.forEach((item) => {
                if(props.max === 0 || lists.value.length < props.max){
                  lists.value.push(item)
                }
              })
              key.value++

              nextTick(() => {
                let fields = proxy.$refs.pGroup.getFields();
                fields.map((item, index) => {
                  if(map.get(lists.value[index].index_key) !== undefined){
                    item.resetField(map.get(lists.value[index].index_key))
                  }
                })
              })
            }
          }
        })
      }
      return {
        resetField,
        validate,
        delField,
        startDraggable,
        endDraggable,
        _addField,
        openDialog,
        fill,
        handleListen,
        key,
        labelProps,
        isDisabled,
        lists,
        attrs,
        value,
        def,
        pGroup
      }
    }
  }
</script>
<style lang="scss" scoped>

  .dynamic-box{
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    padding: 10px 4px;
  }
  .field-box {
    padding-right: 60px;
    padding-left: 2px;
    position: relative;
    background-color: #FFFFFF;
    //margin-left: -22px;

    :deep(.el-form-item) {
      margin-bottom: 10px;
    }

    .field-box-name {
      position: absolute;
      left: 0;
      top: 0;
    }

    .field-box-tool {
      position: absolute;
      right: 5px;
      top: 0;

      .field-box-del {
        //color: red;
        margin-right: 6px;
      }

      .field-box-romve {
        /*color:#409EFF;*/
      }
    }
  }
</style>
